import { createSlice } from '@reduxjs/toolkit';

export const questionReducer = createSlice({
	name: 'questions',
	initialState: {
		queue: [],
		answers: [],
		trace: 0,
	},
	reducers: {
		startQuizAction: (state, action) => {
			let { questions, answers } = action.payload;
			return {
				...state,
				queue: questions,
				answers: answers,
			};
		},
		moveNextAction: (state) => {
			return {
				...state,
				trace: state.trace + 1,
			};
		},
		movePrevAction: (state) => {
			return {
				...state,
				trace: state.trace - 1,
			};
		},
	},
});

export const {
	startQuizAction,
	moveNextAction,
	movePrevAction,
} = questionReducer.actions;

export default questionReducer.reducer;
